import React, { memo, Suspense, useEffect, useMemo, useState } from 'react';
import ErrorBoundary from '../../components/tailwind/ErrorBoundary';
import { LoadingSpinner } from '../../components/tailwind';
import { ShopRootProps } from '../../ShopRoot';
import PageHeader from './components/PageHeader';
import { lazyRetry } from '../../utils/lazyRetry';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { ShopRoutes } from '@types';
import { pageContent as PAGE_CONTENT } from '@/features/shop/shop.settings';
import usePageDesign from './hooks/usePageDesign';

const MyRTICPage = React.lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "myRticPage" */ './pages/MyRTICPage'), 'myRticPage')
);
const NascarPage = React.lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "nascarPage" */ './pages/NascarPage'), 'nascarPage')
);
const AuthorizedRetailersPage = React.lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "authorizedRetailersPage" */ './pages/AuthorizedRetailersPage'),
    'authorizedRetailersPage'
  )
);
const PageDesignPage = React.lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "pageDesignPage" */ './pages/PageDesignPage'), 'pageDesign')
);
const UltraLightPage = React.lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "ultraLightPage" */ './pages/UltraLightPage'), 'ultraLightPage')
);
const WarrantyPage = React.lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "warrantyPage" */ './pages/WarrantyPage'), 'warrantyPage')
);

const ShopPage = ({ pageDesign: rawPageDesign = {} }: ShopRootProps) => {
  const location = useLocation();
  const [pageContent, setPageContent] = useState(PAGE_CONTENT[location.pathname]);
  const { pageDesign, isLoading } = usePageDesign(rawPageDesign);

  useEffect(() => {
    setPageContent(PAGE_CONTENT[location.pathname] || {});
  }, [location]);

  const { crumbs, header } = pageContent || {};

  const headerComponent = useMemo(() => {
    return (
      <ErrorBoundary>
        <PageHeader header={header} crumbs={crumbs} />
      </ErrorBoundary>
    );
  }, [header, crumbs]);

  return (
    <div className='tw-min-h-screen'>
      {isLoading && <LoadingSpinner delay={1000} />}

      <Routes>
        {/*<Route
            path={ShopRoutes.HARD_COOLERS}
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <HardCoolersPage {...{ pageContent, pageDesign, headerComponent }} />
              </Suspense>
            }
          />*/}
        <Route
          path={ShopRoutes.MY_RTIC}
          element={
            <Suspense fallback={<LoadingSpinner delay={0} />}>
              <MyRTICPage {...{ pageContent, pageDesign, headerComponent }} />
            </Suspense>
          }
        />
        <Route
          path={ShopRoutes.NASCAR}
          element={
            <Suspense fallback={<LoadingSpinner delay={0} />}>
              <NascarPage {...{ pageContent, pageDesign }} />
            </Suspense>
          }
        />
        <Route
          path={ShopRoutes.ULTRA_LIGHT_COOLERS}
          element={
            <Suspense fallback={<LoadingSpinner delay={0} />}>
              <UltraLightPage {...{ pageContent, pageDesign }} />
            </Suspense>
          }
        />
        <Route
          path={ShopRoutes.AUTHORIZED_RETAILERS}
          element={
            <Suspense fallback={<LoadingSpinner delay={0} />}>
              <AuthorizedRetailersPage {...{ pageContent, pageDesign }} />
            </Suspense>
          }
        />
        <Route
          path={ShopRoutes.WARRANTY}
          element={
            <Suspense fallback={<LoadingSpinner delay={0} />}>
              <WarrantyPage {...{ pageContent, pageDesign }} />
            </Suspense>
          }
        />
        <Route
          path='*'
          element={
            <Suspense fallback={<LoadingSpinner delay={0} />}>
              <PageDesignPage {...{ pageContent, pageDesign }} />
            </Suspense>
          }
        />
      </Routes>
    </div>
  );
};

export default memo(ShopPage);
